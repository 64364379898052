$tile-padding-bottom-mobile: 48px;
$tile-details-padding-top: 20px;
$tile-first-margin-left: 0;
$tile-last-margin-right: 0;
$tile-last-padding-bottom-mobile: 0;
$tile-link-margin-left-right: 24px;
$tile-link-padding-top: 5px;

:root {
    // link
    --msv-content-block-link-color: var(--msv-font-primary-color);
    --msv-content-block-link-font-size: var(--msv-body-font-size-m);
}

.image-text{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 18px 10px 10px;
    color: #FFFFFF;

    @media (max-width: 600px) {
        position: relative;
        color: #212225;
        padding: 0px 10px 10px;
        background-image: none !important;
    }
}
.ImageText { 
    @media (max-width: 600px) {
    background-image: none !important;
}

}

.content-text{
    line-height: 120%;
    text-shadow: 1px 1px 5px #000000;
    font-size: 28px;
    font-weight: 600;
    @media (max-width: 600px) {
        text-shadow: none;
        font-weight: 300;
    }
}

.ms-content-block__cta{
    display: flex;
    flex-direction: column;
}

// .msc-cta__primary > div{
//     font-size: 18px !important;
//     font-weight: 700 !important;
//     line-height: 21px !important;
//     letter-spacing: 0em !important;
//     text-align: left !important;
//     color: #0D0F10 !important;
// }

.msc-cta__secondary{
    margin-left: 0px !important;

    font-family: "Ensign:Sans",Arial,"noto sans" ,sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    text-decoration: none !important;
    /* or 21px */


    color: #007DA5 !important;
}

.ms-content-block__details{
    padding-top: 0px !important;
    // padding-bottom: 32px;
    max-width: 92% !important;
}

.ms-product-search-result__item{

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // justify-content: space-between;
}

.msc-cta__primary{
    font-family: "Ensign:Sans",Arial,"noto sans" ,sans-serif !important;
    // font-style: normal !important;
    // font-weight: 700 !important;
    // font-size: 18px !important;
    // line-height: 115% !important;
    text-decoration: none !important;
    // /* or 21px */


    // color: #0D0F10 !important;
    color: #007DA5 !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
}

.ms-content-block {
    &[data-m-layout="tile"] {
        margin: auto;
        width: fit-content;

        .ms-content-block__link {
            display: block;
        }

        .msc-cta__primary {
            @include button-link(var(--msv-content-block-link-color));
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-content-block-link-font-size), $msv-line-height-m);
        }

        .ms-content-block__details {
            padding-top: $tile-details-padding-top;
        }

        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;

            .ms-content-block__cta {
                padding-top: $tile-link-padding-top;

                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right;
                    text-decoration: underline;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }
                }
            }
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-right: $tile-link-margin-left-right;

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        &.textplacement__center .ms-content-block__details {
            text-align: center;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^="msc-cta__"] {
                    margin-left: $tile-link-margin-left-right / 2;
                    margin-right: $tile-link-margin-left-right / 2;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            padding-bottom: $tile-padding-bottom-mobile;
            width: 100%;

            & .ms-content-block__image img {
                width: 100%;
            }

            & .ms-content-block__image svg {
                width: 100%;
            }
        }
    }
}

.col-sm:first-child .ms-content-block[data-m-layout="tile"]:first-child,
.col-md:first-child .ms-content-block[data-m-layout="tile"]:first-child {
    margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child,
.col-md:last-child .ms-content-block[data-m-layout="tile"]:last-child {
    margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-m) {
    &.col-sm:last-child .ms-content-block[data-m-layout="tile"]:last-child {
        padding-bottom: $tile-last-padding-bottom-mobile;
    }
}
