$transition-collapse: min-height 0.35s ease;

.collapse {
    &:not(.show) {
        display: none;
    }
}

.collapsing {
    @include transition($transition-collapse);
    height: 0;
    min-height: 0;
    overflow: hidden;
    position: relative;
}


html {scroll-behavior: smooth;}
    
.sc-buttonInches {
    background: white;
    cursor: pointer;
}
    
.sc-buttonInches + input[type=radio]:checked ~ .sizingChartTable .scs .scr .scc {
    display: none;
}

        
.sc-buttonCenti {
    background: white;
    cursor: pointer;
}
        
.sc-buttonCenti + input[type=radio]:checked ~ .sizingChartTable .scs .scr .sci {
    display: none;
}

    