$msv-btn-icon-to-text-gap: 4px;
$msv-btn-border-radius: 2px;

// vars font size and colors

.msc-btn {
    @include button-default();
    display: inline-block;
    border: 1px solid transparent;
    border-radius: $msv-btn-border-radius;
    font-family: $msv-primary-font-family;
    text-decoration: none;
    text-align: center;
    min-width: auto;
    // background: $msv-primary;
    // overrides
    line-height: 40px;
    margin: 0;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    min-width: 43px;

    &:not(:disabled) {
        cursor: pointer;
    }

    &.primary {
        color: $msv-primary;
        background-image: linear-gradient(155deg, #5cadbf, #2f7d99);
    }

    &.label {
        font-size: 12px;
        color: #666;
        float: left;
        text-align: left;
        padding-left: 5px;
    }

    &.select {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        vertical-align: top;
        color: #0b6081;
    }
}
