@import "button";
@import "collapse";
@import "form";
@import "image";
@import "reset";

button.ms-checkout-payment-instrument__btn-save.msc-btn.btn-primary {
    display: none;
}

button.ms-checkout-payment-instrument__btn-edit.msc-btn.btn-link {
    display: none;
}