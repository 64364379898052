$msv-order-summary-font: $msv-primary-font-family;
$msv-order-summary-padding: 11px;
$msv-order-summary-heading-padding-bottom: 7px;
$msv-order-summary-items-border-radius: 2px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;

//style presets
:root {
    --msv-order-summary-bg: var(--msv-bg-color);
    --msv-order-summary-font-color: var(--msv-font-primary-color);
    --msv-order-summary-font-size: var(--msv-body-font-size-l);
    --msv-order-summary-border: var(--msv-font-primary-color);

    // heading
    --msv-order-summary-heading-font-color: var(--msv-font-primary-color);
    --msv-order-summary-heading-font-size: var(--msv-body-font-size-xl);
}

.msc-order-summary {
    &-wrapper {
        align-items: center;
        background-color: var(--msv-order-summary-bg);
        display: flex;
        flex: 0 0 368px;
        flex-direction: column;
        margin: 0 auto;
        padding: $msv-order-summary-padding;
        height: fit-content;
    }

    @media screen and (max-width: $msv-breakpoint-s) {
        margin: unset;
    }

    &__heading {
        @include font-content(var(--msv-font-weight-heavy),
        var(--msv-order-summary-heading-font-size), $msv-line-height-xl);
        color: var(--msv-order-summary-heading-font-color);
        font-style: $msv-order-summary-font;
        padding-bottom: $msv-order-summary-heading-padding-bottom;
        width: 100%;
    }

    &__items {
        color: var(--msv-order-summary-font-color);
        border-radius: $msv-order-summary-items-border-radius;

        @include font-content(var(--msv-font-weight-normal), var(--msv-order-summary-font-size), $msv-line-height-l);
        width: 100%;

        .msc-promo-code {
            &__group {
                display: flex;
                padding-top: 10px;
            }

            &__input-box {
                flex: auto;
                margin-right: 20px;
                padding-left: 8px;
            }
        }
    }

    &__line {
        &-net-price,
        &-tax-amount,
        &-shipping,
        &-loyalty,
        &-gift-card,
        &-other-charges,
        &-total-discounts {
            padding: 10px 0 10px;
        }

        &-total {
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-order-summary-font-size), $msv-line-height-l);
            padding: 10px 0 20px;
            border-top: 1px solid var(--msv-order-summary-border);
            text-transform: uppercase;
        }
    }

    &__value {
        float: right;
        text-align: right;
        font-weight: var(--msv-font-weight-bold);
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .msc-order-summary-wrapper {
        margin: 0;
    }
}
