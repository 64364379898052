.custom-nav-layout {
    display: flex;
    justify-content: space-between;
    height: 42px;
    box-shadow: 0 0 3px #ccc;

    & > * {
        vertical-align: middle;
    }
}
