.select-input-container {
    display: flex;
    flex-direction: column;

    // .label {

    // }
}

.ms-buybox .msc-dropdown__select{
    border-color: hsl(0, 0%, 80%) !important;
}

// TODO set color vars
a {
    color: #177c9c;
    text-decoration: none;
}
