// bootstrap color variables
$msv-white: #fff;
$msv-black: #000000;
$msv-blue: #0062af;
$msv-green: #107c10;
$msv-red: #a80000;
$msv-pink: #fcdfdf;
$msv-cyan: #0776d1;
$msv-yellow: #ffff00;
$msv-grey: #323130;

$msv-gray-100: #f8f7f6;
$msv-gray-200: #e8e8e8;
$msv-gray-300: #d1d1d1;
$msv-gray-400: #6b727a;
$msv-gray-500: #616365;
$msv-gray-800: #8f8f8f;
$msv-gray-900: #1d1d1d;
$msv-gray-1000: #2d2d2d;
$msv-gray-tint: #495057;

$msv-primary: #01b6d1;
$msv-primary-hover: #01b6d1;
$msv-secondary: #01b6d1;
$msv-secondary-hover: #01b6d1;

$msv-text-color: $msv-gray-900;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #f3f2f1;

// Social button color variables
$facebook-bg-color: #3c3c41;
$facebook-hover-color: #1d1d1d;
$microsoft-bg-color: #3c3c41;
$microsoft-hover-color: #1d1d1d;
$social-bg-color: #3c3c41;
$social-hover-color: #1d1d1d;

$msv-unfilled-rating-color: $msv-gray-800;

// TODO use Eden White 

// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-white};

    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};

    // Borders
    --msv-border-color: #{$msv-gray-100};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$msv-secondary-hover};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msv-flyout-bg-color: #{$msv-white};
}

// html base elements
p {
    display: block;
    padding: 1em 0;
}

a {
    color: #{$msv-primary};
}
