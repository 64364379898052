// .footer-wrapper {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
// }

.footer_logo {
    width: 116px;
}

.store_footer {
    height: 360px;
    padding-top: 76px !important;
    padding-right: 366px !important;
    padding-bottom: 53px !important;
    padding-left: 239px !important;
    .ms-container-background-image {
        background-position: left !important;
        background-size: contain !important;
        min-height: 231px !important;
    }
} 

footer {
    .row {
        padding-top: 40px;
        margin-left: 235px;
    }
    background: #EFF0F0;
    border-top: 1px solid #878A8C;
}

.ms-footer__list-item {
    display: inline-flex;
    padding-right: 14px;
}

.footer-module {
    border-top: 1px solid #97999b;
    margin-top: 128px;
    padding: 40px 26px 90px;
    background: #eff0f0;
    font-display: auto;
    font-family: var(--sans);
    font-size: 12px;
    font-weight: 300;
    line-height: 1.4;
    z-index: 99;
    transition: opacity 1s;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 116px 104px 550px 1fr;
    grid-template-rows: 1fr 175px 1fr;
    grid-template-areas:
        '. logo . . . '
        '. logo . links . '
        '. logo . . . ';
    position: relative;
    transition: grid-template-columns 0.5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (max-width: 840px) {
        display: flex !important;
        flex-direction: column;
    }

    @media (max-width: 960px) {
        grid-template-columns: 1fr 116px 80px 550px 1fr;
    }

    .footerLogo {
        margin: 26px 0 12px;
        grid-area: logo;
        display: flex;
        position: relative;
        justify-content: left;

        @media (max-width: 480px) {
            margin-right: 0;
            text-align: center;
            display: none;
        }
    }

    .footerLogoMobile {
        margin: 26px 0 12px;
        grid-area: logo;
        display: flex;
        position: relative;
        justify-content: left;
        display: none;

        @media (max-width: 480px) {
            margin-right: 0;
            text-align: center;
            display: inline;
        }
    }

    .PFlinkBlock {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: left;
        grid-area: links;
        position: relative;

        .PFstandardLinks {
            a:active,
            a:link {
                color: #212225 !important;
                color: var(--footerThemeText, var(--text120));
                padding: 16px 11px 16px 0 !important;
                padding: var(--headerSpacing16) 11px;
            }

            @media (max-width: 480px) {
                margin-right: 0;
                text-align: center;
            }

            @media (max-width: 640px) {
                margin-right: 0;
                justify-content: center;
                height: auto;
                width: 100%;
            }
        }
    }

    .PFlinkBlock {
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column: 4;
        -ms-grid-column: 4;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        justify-content: left;
        grid-area: links;
        position: relative;

        .PFstandardLinks {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            position: relative;

            :after {
                content: '';
                width: 100%;
                display: block;
                border-bottom: 2px solid #c3c3c4;
                height: 2px;
                position: absolute;
                bottom: 0;
                -webkit-border-before: 0;
                border-block-start: 0;
            }

            @media (max-width: 840px) {
                margin-right: 13px;
                justify-content: flex-end;
                align-items: center;
                text-align: right;
                height: 45px;
                // width: calc(50% - 16px);

                :after {
                    content: none;
                }
            }

            @media (max-width: 480px) {
                margin-right: 0;
                text-align: center;
            }
        }

        @media (max-width: 840px) {
            flex-direction: row;
            margin-right: 0;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }

    .PFfooterLegal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
        line-height: 1.4;
        position: relative;
        text-align: left;
        font-weight: 300;
        grid-area: links;
        position: absolute;
        bottom: 0;

        .privacy-and-cookie-p {
            font-weight: 400;
            font-size: 12px;
            line-height: 1.64;
            text-align: center;
            color: #666;
            display: inline-block;
        }

        a:active:first-of-type,
        a:first-of-type,
        a:link:first-of-type,
        a:visited:first-of-type {
            margin-left: 0;
        }

        a,
        a:active,
        a:link,
        a:visited {
            margin: 4px 3px;
            text-decoration: none;
            color: var(--footerThemeText, var(--text120));
            display: inline-block;
            font-weight: 300;
        }

        @media (max-width: 840px) {
            position: relative;
            margin-right: 0;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-top: 15px;
        }
    }
}
