// Import bootstrap css

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";
@import "../00-settings/responsive-breakpoints";

.row {
    margin: 0;
}

div[class^="col"] {
    padding: 0;
    margin: 0;
}

// Container width
body .container {
    padding-left: 20px;
    padding-right: 20px;

    @media screen and (min-width: $msv-breakpoint-s) {
        max-width: $msv-breakpoint-l;
        width: 100%;
    }
    @media screen and (min-width: 1323px) {
        max-width: $msv-breakpoint-xl;
        width: 100%;
    }
}

// .default-container.default-container.container.category-container {
//     padding-top: 40px;
// }

.categoryContent{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 92%;

    @media (max-width: 1323px) {
        align-items: center;  
    }
}

.default-container.default-container.container.category-container > div.row > div.col-md-3 {
    flex: none;
    max-width: 100%;

    @media (min-width: 756px) {
        max-width: 50%;
    }
    @media (min-width: 1323px) {
        max-width: 25%;
    }
}

.default-container.default-container.container > div.row > div.col-md-3 {
    flex: none;
    max-width: 100%;

    @media (min-width: 756px) {
        max-width: 50%;
    }
    @media (min-width: 1323px) {
        max-width: 25%;
    }
}

.categoryContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media (max-width: 1184px) {
        grid-template-columns: 1fr 1fr 1fr; 
    }

    @media (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
        
    }
    @media (max-width: 593px) {
        grid-template-columns: 1fr;
        
    }

}
