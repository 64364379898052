.pop-over {
    // display: flex;
    justify-content: space-between;
    box-shadow: 0 0 3px #ccc;
    width: 298px;
    background: var(--msv-bg-color);
    z-index: 1000;
    
    menu {
        li {
            text-decoration: none;
            padding: var(--headerSpacing8) 0;
            display: block;
            font-weight: 400;
        }
    }
    // & > * {
    //     vertical-align: middle;
    // }

    .user_account {
        border-bottom: 1px solid #979797;
        padding-left: 0;
        min-height: 40px;
        padding-bottom: 8px;
        padding-top: 8px;
        margin-top: 11px;
    }
}
